import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';

import './StyledVerticalTimelineElement.css';
import Colors from '../styles/colors';


type Props = {
    children: React.ReactNode,
    themeColor: string,
    date: string,
    title: string,
    company: string,
    href: string,
    location: string,
};

const StyledVertivalTimelineElement = (props: Props) =>
    <VerticalTimelineElement
        className='custom-vertical-timeline'
        contentStyle={{
            background: Colors.background_secondary,
            color: Colors.text_primary,
            borderTop: `3px solid ${props.themeColor}`,
            boxShadow: 'none',
        }}
        contentArrowStyle={{ borderRight: `10px solid ${Colors.background_secondary}` }}
        iconStyle={{
            background: props.themeColor,
            boxShadow: 'none',
        }}
        date={props.date}>

        <h3>{props.title}</h3>
        <h4>
            <a href={props.href} target='_blank' rel='noopener noreferrer'>
                {props.company}
            </a>
        </h4>
        <h5>{props.location}</h5>

        {props.children}

    </VerticalTimelineElement>;


export default StyledVertivalTimelineElement;