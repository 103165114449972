import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';

import style from './ExperienceSection.module.css';
import Colors from '../../styles/colors';
import StyledVertivalTimelineElement from '../StyledVerticalTimelineElement';


const ExperienceSection = () =>
    <section>
        <VerticalTimeline className={style.vertical_timeline}>
            <StyledVertivalTimelineElement
                themeColor={Colors.theme_pink}
                date='April 2017 - March 2020'
                title='Full-Stack Software Engineer'
                company='Ti&m'
                href='https://ti8m.com/'
                location='Zürich, Switzerland'>
                <ul>
                    <li>
                        Designed, developed and delivered new web applications for different Swiss banks
                    </li>
                    <li>
                        Maintenance of existing projects: implementation of new features, bug fixing, improvement of
                        the quality of the code
                    </li>
                </ul>
            </StyledVertivalTimelineElement>
            <StyledVertivalTimelineElement
                themeColor={Colors.theme_yellow}
                date='January 2017 - March 2017'
                title='Mobile App Developer'
                company='UIIP Biogem'
                href='http://www.uiip.it/'
                location='Milan, Italy'>
                <ul>
                    <li>
                        Technical training: AngularJS, Spring and testing tools
                    </li>
                    <li>
                        Cross training: Scrum Methodologies, Teamwork, Communication Management and Public speaking
                    </li>
                </ul>
            </StyledVertivalTimelineElement>
            <StyledVertivalTimelineElement
                themeColor={Colors.theme_green}
                date='April 2020 - Jan 2018'
                title='Internship student'
                company='Log80'
                href='http://www.log80.it/'
                location='Forlì, Italy'>
                <ul>
                    <li>
                        Development and maintenance of websites written in PHP language
                    </li>
                </ul>
            </StyledVertivalTimelineElement>
        </VerticalTimeline>
    </section>;

export default ExperienceSection;