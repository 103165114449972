export default {
    background_primary: '#1d1d1d',
    background_secondary: '#2b2b2b',
    text_primary: '#fff',
    text_secondary: '#848484',
    theme_white: '#fff',
    theme_gray: '#848484',
    theme_green: '#01c853',
    theme_blue: '#2879ff',
    theme_yellow: '#fec107',
    theme_orange: '#ff8f00',
    theme_red: '#ff2d00',
    theme_pink: '#f50057',
    theme_purple: '#6200eb',
};
