import React from 'react';

import defaultStyles from '../../styles/styles.module.css';
import style from './Footer.module.css';


const Footer = () =>
    <footer className={style.container}>
        <span className={defaultStyles.handwriting}>
            Thank You For Visiting.<br />
            Federico Bellini |
            2015 - {new Date().getFullYear()} |
            images: <a
                href='https://unsplash.com/'
                target='_blank'
                rel='noopener noreferrer'>
                Unsplash
            </a>
        </span>
    </footer>;

export default Footer;