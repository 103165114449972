import React from 'react';
import { Sticky } from 'react-sticky';

import defaultStyles from '../styles/styles.module.css';
import Colors from '../styles/colors';


type Props = {
    title: string,
};

const StyledStickyHeader = ({ title }: Props) =>
    <Sticky disableCompensation>
        {(stickyProps) =>
            <h2 className={defaultStyles.handwriting}
                style={{
                    ...stickyProps.style,
                    backgroundColor: stickyProps.isSticky ? Colors.background_primary : 'transparent',
                    color: Colors.text_primary,
                    fontSize: '3rem',
                    zIndex: 1,
                    position: stickyProps.isSticky ? 'fixed' : 'absolute',
                    borderBottom: stickyProps.isSticky ? `solid ${Colors.theme_white} 2px` : 'none',
                    marginLeft: stickyProps.isSticky ? 0 : '4rem',
                    paddingLeft: stickyProps.isSticky ? '4rem' : 0,
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                }}>

                {title}
            </h2>
        }
    </Sticky>;

export default StyledStickyHeader;

