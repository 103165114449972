import React from 'react';

import style from './ProfileInfo.module.css';
import defaultStyles from '../../styles/styles.module.css';


const ProfileInfo = () =>
    <div>
        <h1 className={defaultStyles.handwriting}>Hi, I'm </h1>
        <h1 className={defaultStyles.information_text}>Federico Bellini</h1>
        <br />

        <p className={style.information_paragraph}>
            <span className={defaultStyles.handwriting}>a </span>
            <h2 className={defaultStyles.information_text_inverse}>Full-Stack Software Engineer</h2>

            <span className={defaultStyles.handwriting}> with a strong passion for </span>
            <span className={defaultStyles.information_text}>problem solving</span>
            <span className={defaultStyles.handwriting}> and </span>
            <span className={defaultStyles.information_text}>emerging technologies</span>
            <span className={defaultStyles.handwriting}>, I constantly strive to broaden my horizons.</span>
            <br />

            <span className={defaultStyles.handwriting}>Born in August </span>
            <span className={defaultStyles.information_text}>1994</span>
            <span className={defaultStyles.handwriting}> in Forlì, </span>
            <span className={style.italy}>
                <span className={defaultStyles.information_text}>It</span>
                <span className={defaultStyles.information_text}>a</span>
                <span className={defaultStyles.information_text}>ly</span>
            </span>
        </p>
    </div>;

export default ProfileInfo;