import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Colors from '../styles/colors';


type Props = {
    imgSrc: string,
    title: string,
    children: React.ReactNode,
    href: string,
    className: string,
};

const StyledCard = ({ imgSrc, title, children, href, className }: Props) =>
    <Card className={className}>
        <CardActionArea href={href} target='_blank'>
            <CardMedia
                image={imgSrc}
                style={{
                    height: '15rem',
                }}
            />
            <CardContent
                style={{
                    backgroundColor: Colors.background_secondary,
                    height: '12rem',
                }}>
                <h3 style={{
                    color: Colors.text_primary,
                }}>
                    {title}
                </h3>
                <div style={{
                    color: Colors.text_secondary,
                }}>
                    {children}
                </div>
            </CardContent>
        </CardActionArea>
    </Card>;

export default StyledCard;
