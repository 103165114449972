import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';

import style from './EducationSection.module.css';
import Colors from '../../styles/colors';
import StyledVertivalTimelineElement from '../StyledVerticalTimelineElement';

const EducationSection = () =>
    <section className={style.container}>
        <VerticalTimeline className={style.vertical_timeline}>
            <StyledVertivalTimelineElement
                themeColor={Colors.theme_purple}
                date='September 2013 - October 2016'
                title='Bachelor in Computer Science and Engineering'
                company='University of Bologna'
                href='https://www.unibo.it/en'
                location='Cesena, Italy'>
                <ul>
                    <li>
                        Graduated in October 2016 with 108/110
                    </li>
                    <li>
                        Thesis: Design and development of an IOT hands-free system to be used in emergency rooms
                        of hospitals
                    </li>
                </ul>
            </StyledVertivalTimelineElement>
        </VerticalTimeline>
    </section>;

export default EducationSection;