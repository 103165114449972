import React from 'react';
import { StickyContainer } from 'react-sticky';


import style from './profilePage.module.css';
import ProfileHeader from '../components/profile/ProfileHeader';
import ExperienceSection from '../components/experience/ExperienceSection';
import EducationSection from '../components/education/EducationSection';
import ProjectsSection from '../components/projects/ProjectsSection';
import StyledStickyHeader from '../components/StyledStickyHeader';
import ContactSection from '../components/contact/ContactSection';
import Footer from '../components/footer/Footer';


const ProfilePage = () =>
  <div className={style.main_container}>
    <div className={'container ' + style.inner_container} style={{}}>
      <ProfileHeader />

      <StickyContainer>
        <StyledStickyHeader title='Experience' />
        <ExperienceSection />
      </StickyContainer>

      <StickyContainer>
        <StyledStickyHeader title='Education' />
        <EducationSection />
      </StickyContainer>

      <StickyContainer>
        <StyledStickyHeader title='Personal projects' />
        <ProjectsSection />
      </StickyContainer>

      <StickyContainer>
        <StyledStickyHeader title='Contacts' />
        <ContactSection />
      </StickyContainer>
    </div>

    <Footer />

  </div>;

export default ProfilePage;