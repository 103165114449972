import React from 'react';

import backgroundImage from '../../assets/images/profile_background.jpg';

const ProfileBackgroundImage = () =>
    <img src={backgroundImage}
        style={{
            minHeight: '100%',
            objectFit: 'cover',
        }}
        alt='background' />;

export default ProfileBackgroundImage;