import React from 'react';

import style from './ProjectsSection.module.css';
import StyledCard from '../StyledCard';
import squeezeMeImage from '../../assets/images/SqueezeMe.png';
import personalWebsiteImage from '../../assets/images/PersonalWebsite.png';

const ProjectsSection = () =>
    <section className={style.container}>
        <StyledCard
            imgSrc={squeezeMeImage}
            title='SqueezeMe'
            href='https://apps.apple.com/us/app/squeezeme/id1504747047'
            className={style.card}>
            <p>Simple mobile game publicly available in the AppStore and Google Play store.<br />
                Tech stack: React Native app and backend in Node deployed in AWS.</p>
        </StyledCard>
        <StyledCard
            imgSrc={personalWebsiteImage}
            title='Personal Website'
            href='https://gitlab.com/FedeBell94/personal-website'
            className={style.card}>
            <p>This is the website you are looking right now! <br />
            Have a look at its code in Gitlab.</p>
        </StyledCard>
    </section>;

export default ProjectsSection;