import React from 'react';

import style from './ContactSection.module.css';
import defaultStyles from '../../styles/styles.module.css';
import coffeeSrc from '../../assets/images/coffee.jpg';


const ContactSection = () =>
    <section className={style.container}>
        <div className={style.text_container}>
            <span className={defaultStyles.handwriting}>
                Want to know more about me?
                <br />
                Let's take a <a
                    href='mailto:federicobellini94@gmail.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={defaultStyles.handwriting}>
                    coffee
                </a>
            </span>
        </div>
        <img className={style.coffee_image} src={coffeeSrc} alt='coffee' />
    </section>;

export default ContactSection;