import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ProfilePage from './pages/profilePage';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Redirect to='/profile' />
        </Route>
        <Route exact path='/profile' component={ProfilePage} />
        <Route component={() => <div>Not Found</div>} /> {/*TODO not found page*/}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
