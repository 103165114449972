import React from 'react';
import { style } from 'typestyle';

import profilePortrait from '../../assets/images/profile_picture.png';
import Colors from '../../styles/colors';


const ProfilePortrait = () => {
    const styleClassName = style({
        width: '100%',
        borderRadius: '50%',
        backgroundColor: Colors.theme_pink,
        $nest: {
            '&:hover': {
                backgroundColor: Colors.theme_purple,
            },
        },
    });

    return <img src={profilePortrait}
        className={styleClassName}
        alt='profile' />;
};

export default ProfilePortrait;
