import React from 'react';
import { FaLinkedinIn, FaGitlab, FaHackerrank } from 'react-icons/fa';

import style from './ProfileHeader.module.css';
import Colors from '../../styles/colors';
import StyledIconButton from '../StyledIconButton';
import ProfileBackgroundImage from './ProfileBackgroundImage';
import ProfilePortrait from './ProfilePortrait';
import ProfileInfo from './ProfileInfo';


const ProfileHeader = () =>
    <header>
        <div className={style.background_image_container}>
            <ProfileBackgroundImage />
        </div>
        <div className={style.profile_container}>
            <div className={style.image_container}>
                <ProfilePortrait />
                <div className={style.icons_container}>
                    <StyledIconButton hoverColor={Colors.theme_blue} href='https://www.linkedin.com/in/federico-bellini-2513b4b5/'>
                        <FaLinkedinIn />
                    </StyledIconButton>
                    <StyledIconButton hoverColor={Colors.theme_orange} href='https://gitlab.com/FedeBell94'>
                        <FaGitlab />
                    </StyledIconButton>
                    <StyledIconButton hoverColor={Colors.theme_green} href='https://www.hackerrank.com/FedeBell94'>
                        <FaHackerrank />
                    </StyledIconButton>
                </div>
            </div>
            <div className={style.info_container}>
                <ProfileInfo />
            </div>
        </div>
        <div className='vertical-timeline vertical-timeline--two-columns'
            style={{ height: '5rem' }} />
    </header>;

export default ProfileHeader;