import React from 'react';
import { IconButton } from '@material-ui/core';
import { style } from 'typestyle';

import Colors from '../styles/colors';


type Props = {
    children: React.ReactElement,
    href: string,
    hoverColor: string,
};

const StyledIconButton = ({ children, href, hoverColor }: Props) => {
    const styleClassName = style({
        color: Colors.theme_gray,
        $nest: {
            '&:hover': {
                color: hoverColor,
            },
        },
    });

    const childProps = {
        size: '2rem',
        className: styleClassName,
    };

    return (
        <a href={href}
            target='_blank'
            rel='noopener noreferrer'>
            <IconButton style={{ outlineWidth: 0 }}>
                {React.cloneElement(children, childProps)}
            </IconButton>
        </a>
    );
};

export default StyledIconButton;